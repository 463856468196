import { AutocompleteAddress } from "@whitelabel-webapp/address/shared/models";
import { chainAboyeur } from "@whitelabel-webapp/chain/config";
import { useChain } from "@whitelabel-webapp/chain/shared/chain-store";
import { Loading } from "@whitelabel-webapp/shared/design-system";
import { useRouter } from "next/router";
import { useEffect } from "react";

import * as S from "./styles";

type ClosestMerchantByGeolocationProps = {
  autocompleteAddress: AutocompleteAddress;
  onMerchantNotFound: () => void;
};

export const ClosestMerchantByGeolocation: React.VFC<
  ClosestMerchantByGeolocationProps
> = ({ autocompleteAddress, onMerchantNotFound }) => {
  const { chain } = useChain();
  const router = useRouter();

  useEffect(() => {
    const getClosestMerchants = async () => {
      try {
        const closestMerchant = await chain.getClosestMerchant(
          autocompleteAddress.coordinates.latitude,
          autocompleteAddress.coordinates.longitude,
        );

        if (!closestMerchant) {
          onMerchantNotFound();
          return;
        }

        chainAboyeur.events.search.merchantFounded(closestMerchant.id);

        router.push({
          pathname: "/[chain]/[uuid]",
          query: { chain: chain.slug, uuid: closestMerchant.id },
        });
      } catch {
        return;
      }
    };

    getClosestMerchants();
  }, [onMerchantNotFound, chain, autocompleteAddress]);

  return (
    <S.Wrapper>
      <Loading variant="large" color="primary">
        Procurando lojas próximas a você...
      </Loading>
    </S.Wrapper>
  );
};
