import {
  Flex,
  Heading,
  ImageWithFallback,
  Button as PomodoroButton,
  Icon as PomodoroIcon,
  Text as PomodoroText,
} from '@whitelabel-webapp/shared/design-system';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  height: 100%;
  flex: auto;
  flex-direction: column;

  margin: ${({ theme }) => theme.space.regular};
  padding: ${({ theme }) => theme.space.largest};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.grayLight};
  border-radius: ${({ theme }) => theme.radii.m};
  box-sizing: border-box;
`;

export const Body = styled(Flex)`
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  flex-grow: 1;
`;

export const Logo = styled(ImageWithFallback)`
  border-radius: ${({ theme }) => theme.radii.l};
`;

export const Title = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes.l};
  line-height: ${({ theme }) => theme.space.larger};
`;

export const Description = styled(Heading)`
  line-height: normal;
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  color: ${({ theme }) => theme.colors.grayDarkest};
`;

export const Footer = styled(Flex)`
  flex-direction: column;
  align-items: center;

  gap: ${({ theme }) => theme.space.regular};
`;

export const Button = styled(PomodoroButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${({ theme }) => theme.fontSizes.tsm};
`;

export const Icon = styled(PomodoroIcon)`
  margin-right: ${({ theme }) => theme.space.smallest};
`;

export const Text = styled(PomodoroText)`
  text-align: center;
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: 14px;

  & a {
    color: ${({ theme }) => theme.colors.grayDarkest};
  }
`;
