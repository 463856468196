import SVG from 'react-inlinesvg';

import * as S from './styles';

type ChainedMerchantNotFoundProps = {
  onRetry: () => void;
};

export const ChainedMerchantNotFound = ({ onRetry }: ChainedMerchantNotFoundProps) => {
  return (
    <S.Wrapper>
      <SVG src="/images/address/missing_location.svg" />
      <S.Title>Não encontramos uma loja próxima a você</S.Title>
      <S.Description>Poxa, ainda não atendemos no seu endereço. Deseja tentar outro?</S.Description>
      <S.TextLink onClick={onRetry}>Trocar endereço</S.TextLink>
    </S.Wrapper>
  );
};
