import { Chain } from '@whitelabel-webapp/chain/shared/models';
import { Location } from '@whitelabel-webapp/shared/design-system';
import { Folders, Sizes, getImageURL } from '@whitelabel-webapp/shared/image-utils';

import * as S from './styles';
import { CHAIN_LOGO_URL } from "@whitelabel-webapp/shared/constants";

type ClosestMerchantUncustomizedProps = {
  chain: Chain;
  onGeolocationUsage?: () => void;
  hasGeolocationReady: boolean;
  onSearchAddress: () => void;
  privacyPolicyLink?: string;
};

export const ClosestMerchantUncustomized: React.VFC<ClosestMerchantUncustomizedProps> = ({
  chain,
  onGeolocationUsage,
  hasGeolocationReady,
  onSearchAddress,
  privacyPolicyLink,
}) => {
  const logoUrl = `${CHAIN_LOGO_URL}/${chain.logoUrl}`;

  return (
    <S.Wrapper>
      <S.Body>
        <S.Logo
          src={logoUrl}
          fallbackSrc="/images/chain/logo_fallback.png"
          objectFit="contain"
          layout="fixed"
          width={158}
          height={158}
        />
        <S.Title forwardedAs="h1">Peça da unidade mais próxima a você</S.Title>
        <S.Description>
          Para encontrar as lojas, digite seu endereço ou use a localização do seu celular :)
        </S.Description>
      </S.Body>
      <S.Footer>
        <S.Button onClick={onSearchAddress}>Digitar meu endereço</S.Button>
        <S.Button onClick={onGeolocationUsage} disabled={!hasGeolocationReady} variant="tertiary">
          <S.Icon component={Location} size="xs" />
          Usar minha localização
        </S.Button>
        {privacyPolicyLink && (
          <S.Text>
            Seus dados pessoais serão usados para te atender,{' '}
            <a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
              conforme as Diretrizes de privacidade.
            </a>
          </S.Text>
        )}
      </S.Footer>
    </S.Wrapper>
  );
};
