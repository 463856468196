import { Dialog } from '@whitelabel-webapp/shared/design-system';
import styled from 'styled-components';

export const Container = styled(Dialog)`
  width: 100%;
  max-width: 310px;
  text-align: center;
`;

export const Header = styled(Dialog.Header)`
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.space.regular};
`;

export const Footer = styled(Dialog.Footer)`
  justify-content: center;
  padding-top: ${({ theme }) => theme.space.regular};
`;
