import { useAddress } from '@whitelabel-webapp/address/shared/address-store';
import { AutocompleteAddress, AutocompleteAddresses } from '@whitelabel-webapp/address/shared/models';
import { chainAboyeur } from '@whitelabel-webapp/chain/config';
import { useChain } from "@whitelabel-webapp/chain/shared/chain-store";
import dynamic from 'next/dynamic';
import { useMemo, useState } from 'react';
import { usePosition } from 'use-position';

import { ChainedMerchantNotFound } from '../ChainedMerchantNotFound/ChainedMerchantNotFound';
import { ClosestMerchantByGeolocation } from '../ClosestMerchantByGeolocation';
import { ClosestMerchantCustomizedProps } from '../ClosestMerchantCustomized';
import { ClosestMerchantUncustomized } from '../ClosestMerchantUncustomized';
import { GeolocationPermissionError } from '../GeolocationPermissionError';

const ClosestMerchantCustomized = dynamic<ClosestMerchantCustomizedProps>(
  () => import('../ClosestMerchantCustomized').then(({ ClosestMerchantCustomized }) => ClosestMerchantCustomized),
  {
    ssr: false,
  }
);

type GeolocationStatus = 'IDLE' | 'SEARCHING_MERCHANTS' | 'NOT_FOUND' | 'ERROR';
type UsePosition = {
  latitude?: number;
  longitude?: number;
  error?: string;
};

export const ClosestMerchant: React.VFC = () => {
  const { chain } = useChain();
  const { openAddress } = useAddress();

  const { error, latitude, longitude } = usePosition(true) as UsePosition;
  const hasGeolocationReady = useMemo<boolean>(() => {
    if (!latitude && !longitude && !error) return false;

    return true;
  }, [latitude, longitude, error]);

  const [autocompleteAddress, setAutocompleteAddress] = useState<AutocompleteAddress | undefined>();
  const [geolocationStatus, setGeolocationStatus] = useState<GeolocationStatus>('IDLE');

  function handleSearchAddress() {
    openAddress('CHAIN');
  }

  async function handleGeolocationAddress() {
    chainAboyeur.events.search.useMyLocation();
    if (!latitude && !longitude) {
      if (error) {
        setGeolocationStatus('ERROR');
      }
      return;
    }

    const addresses = AutocompleteAddresses.fromApi(
      await AutocompleteAddresses.getAddressesByReverseGeocode(latitude as number, longitude as number)
    );

    if (!addresses?.list) return;

    chainAboyeur.events.search.closestMerchant();
    setAutocompleteAddress(addresses.list[0]);

    openAddress('CHAIN', {
      defaultAutocompleteAddress: addresses.list[0],
    });
  }

  const handleChainedMerchantNotFound = () => {
    chainAboyeur.events.search.merchantNotFounded();
    setAutocompleteAddress(undefined);
    setGeolocationStatus('NOT_FOUND');
  };

  const hasCustomizedSettings = chain.hasCustomizedSettings();
  const hasErrorOnGeolocation = geolocationStatus === 'ERROR';
  const privacyPolicyLink = chain.getPrivacyPolicyLink();

  return (
    <>
      {hasErrorOnGeolocation && (
        <GeolocationPermissionError
          hasError={hasErrorOnGeolocation}
          closeDialog={() => setGeolocationStatus("IDLE")}
        />
      )}
      {geolocationStatus === "SEARCHING_MERCHANTS" && (
        <ClosestMerchantByGeolocation
          autocompleteAddress={autocompleteAddress as AutocompleteAddress}
          onMerchantNotFound={handleChainedMerchantNotFound}
        />
      )}
      {geolocationStatus === "NOT_FOUND" && (
        <ChainedMerchantNotFound onRetry={handleSearchAddress} />
      )}
      {!hasCustomizedSettings && (
        <ClosestMerchantUncustomized
          chain={chain}
          onGeolocationUsage={handleGeolocationAddress}
          hasGeolocationReady={hasGeolocationReady}
          onSearchAddress={handleSearchAddress}
          privacyPolicyLink={privacyPolicyLink}
        />
      )}
      {hasCustomizedSettings && (
        <ClosestMerchantCustomized
          chain={chain}
          onGeolocationUsage={handleGeolocationAddress}
          hasGeolocationReady={hasGeolocationReady}
          onSearchAddress={handleSearchAddress}
          privacyPolicyLink={privacyPolicyLink}
        />
      )}
    </>
  );
};
