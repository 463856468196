import { Button, Dialog, Heading, Text } from '@whitelabel-webapp/shared/design-system';

import * as S from './styles';

type GeolocationPermissionErrorProps = {
  closeDialog: () => void;
  hasError: boolean;
};

export const GeolocationPermissionError = ({ hasError, closeDialog }: GeolocationPermissionErrorProps) => {
  return (
    <S.Container open={hasError}>
      <S.Header>
        <Heading my={0} variant="small" as="h3">
          Localização desativada
        </Heading>
      </S.Header>
      <Dialog.Body>
        <Text>
          Ative o serviço de localização do seu celular nas configurações do aparelho pra sabermos a sua localização.
        </Text>
      </Dialog.Body>
      <S.Footer>
        <Button onClick={closeDialog} variant="primary">
          Ok, entendi
        </Button>
      </S.Footer>
    </S.Container>
  );
};
